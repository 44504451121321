<template>
  <div>
    <div class="card card-custom card-nobody gutter-b">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche client #{{client.id}}</h3>
        </div>
        <div class="card-toolbar div-center">
          <b-button variant="danger" size="sm" class="btn-icon mr-1" @click="deleteClient()" v-if="proprietes.length === 0"><i class="icon-md fas fa-trash"></i></b-button>
          <b-button variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <b-button variant="primary" size="sm" @click="toggleFiles = !toggleFiles" class="btn-icon mr-1"><i class="icon-md fas fa-file"></i></b-button>
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save" v-if="$store.getters.currentUser.role === 1">
            <i v-if="loader_save === false" class="icon-md fas fa-save"></i>
            <i v-else class="fas fa-circle-notch fa-spin"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3" :class="toggleFiles ? '' : 'd-none'">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">{{$store.back.getText('liste-fichier')}}</h3>
        </div>
        <div class="card-toolbar">
          <b-button variant="primary" size="sm" class="btn-icon mr-1" v-b-modal.import><i class="icon-md fas fa-plus"></i></b-button>
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-row>
            <b-button size="md" variant="success" @click="docs_filter = null" class="ml-5 mr-1">Tout</b-button>
            <b-button size="md" variant="primary" class="mr-1" v-for="(t,i) in params.GEDType"  @click="docs_filter = t.id"  :key="i">{{t.titre.fr}}</b-button>
          </b-row>
          <b-row class="mt-2">
            <b-col md="2" v-for="(d,i) in docsFiltered" :key="i">
              <div class="card text-center border-primary">
                <div class="card-header bg-primary text-white" style="padding: 1rem 1.25rem;">{{getFileName(d)}}</div>
                <div class="card-body" style="padding:1.5rem;">
                  <h6 class="card-subtitle text-muted mb-2">{{$store.back.getParamTitre(params.GEDType,d.ged_type)}}</h6>
                  <h6 class="card-subtitle text-muted mb-2 mt-1">{{$store.back.timestampToDate(d.date_create,false)}}</h6>
                  <b-card-text>
                    <b-button-group>
                      <b-button size="sm" variant="primary" :href="d.url" target="_blank"><i class="fas fa-eye"></i></b-button>
                      <b-button size="sm" variant="danger" @click="removeFile(i)"><i class="fas fa-minus"></i></b-button>
                    </b-button-group>
                  </b-card-text>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('info-client')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('last_name')+':'">
                <b-form-input v-model="client.nom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('first_name')+':'">
                <b-form-input v-model="client.prenom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('phone')+':'">
                <b-form-input v-model="client.telephone" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('phone')+' 2:'">
                <b-form-input v-model="client.telephone2" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('email')+':'">
                <b-form-input v-model="client.email" required></b-form-input>
              </b-form-group>
            </b-col>
            <!--b-col md="4">
              <b-form-group :label="$store.back.getText('type')+':'">
                <select class="form-control" v-model.number="client.type_id">
                  <option v-for="(t,i) in params.ClientType" :key="i" :value="t.id">{{t.titre.fr}}</option>
                </select>
              </b-form-group>
            </b-col-->
            <b-col md="4">
              <b-form-group :label="$store.back.getText('langue')+':'">
                <select class="form-control" v-model.number="client.langue_id">
                  <option v-for="(l,i) in params.Langue" :key="i" :value="l.id">{{l.titre}}</option>
                </select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('taz')+':'">
                <b-form-input v-model="client.taz"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('source')+':'">
                <select class="form-control" v-model.number="client.source_id">
                  <option v-for="(s,i) in params.Source" :key="i" :value="s.id">{{s.titre}}</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('societe')+':'">
                <b-form-input v-model="client.societe.nom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('numero_registre')+':'">
                <b-form-input v-model="client.societe.id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('adresse')+':'">
                <b-form-input v-model="client.societe.adresse"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('ville')+':'">
                <b-form-input v-model="client.societe.ville"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$store.back.getText('pays')+':'">
                <b-form-input v-model="client.societe.pays"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="card-deck">
      <div class="card card-custom gutter-b mt-3">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('list_proprietes')}}</h3>
          </div>
          <div class="card-toolbar">
            <a class="btn btn-icon btn-sm btn-success mr-1" v-b-modal.createPropriete v-if="client.id != null">
              <i class="icon-md fas fa-plus"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Projet</th>
                <th scope="col">Client</th>
                <th scope="col">Type</th>
                <th scope="col">Statut</th>
                <th scope="col">Superficie</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p,i) in proprietes" :key="i">
                <td scope="row">
                  <a :href="'/#/propriete/detail/'+p.id">#{{p.id}}</a>
                </td>
                <td scope="row">
                  <a :href="'/#/projet/detail/'+p.projet.id" v-if="p.projet.id != 0">{{p.projet.titre}}</a>
                  <span v-else>{{p.projet.titre}}</span>
                </td>
                <td scope="row">
                  <a :href="'/#/client/detail/'+p.client.id">{{p.client.nom}}</a>
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.ProprieteType,p.type_id)}}
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.ProprieteStatus,p.status_id)}}
                </td>
                <td scope="row">
                  {{p.superficie}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card card-custom gutter-b mt-3">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('recherche_encours')}}</h3>
          </div>
          <div class="card-toolbar">
            <a class="btn btn-icon btn-sm btn-success mr-1" v-b-modal.search v-if="client.id != null">
              <i class="icon-md fas fa-plus"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">{{searchTmp[0].label}}</th>
                <th scope="col">{{searchTmp[1].label}}</th>
                <th scope="col">{{searchTmp[2].label}}</th>
                <th scope="col">{{searchTmp[3].label}}</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s,i) in client.searchs" :key="i">
                <td scope="row">
                  <span v-if="s[0].value != null">{{$store.back.timestampToDate(s[0].value,false)}}</span>
                  <span v-else>Tout</span>
                </td>
                <td scope="row">
                  <span v-if="s[1].value != null">{{$store.back.getParamTitre(params.ProprieteType,s[1].value)}}</span>
                  <span v-else>Tout</span>
                </td>
                <td scope="row">
                  <span v-if="s[2].value != null">{{params.TransactionType.find(t => t.id === s[2].value).titre.fr}}</span>
                  <span v-else>Tout</span>
                </td>
                <td scope="row">
                  {{s[3].value}}
                </td>
                <td scope="row">
                  <button type="button" class="btn btn-icon mr-1 btn-danger btn-sm" @click="removeSearch(i)"><i class="icon-md fas fa-minus"></i></button>
                  <button type="button" class="btn btn-icon mr-1 btn-primary btn-sm" @click="openSearch(i)"><i class="icon-md fas fa-eye"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Commentaire</h3>
        </div>
      </div>
      <div class="card-body">
        <b-form-group :label="this.$store.back.getText('commentaire')+':'" >
          <b-form-textarea v-model="client.commentaire" rows="4" readonly></b-form-textarea>
        </b-form-group>
        <b-form-group :label="this.$store.back.getText('ajouter')+':'">
          <b-input-group>
            <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
            <template #append>
              <b-button variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="import" title="Importation de document" ref="import">
      <b-form>
        <b-form-group label="Type:">
          <select class="form-control" v-model.number="tmpDoc.ged_type">
            <option v-for="(t,i) in params.GEDType" :key="i" :value="t.id">{{t.titre.fr}}</option>
          </select>
        </b-form-group>
        <b-form-group label="Dociment:">
          <b-form-file  @change="handleFile" placeholder="Selectionnez votre fichier a importer"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button v-if="loaderImportFile === false" variant="primary" class="float-right" @click="addDoc()">
            Valide
          </b-button>
          <b-button v-else variant="info" size="sm" class="btn-icon mr-1 float-right"> <i class="fas fa-circle-notch fa-spin"></i></b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="search" size="xl" title="Recherche de propriete" ref="search">
      <b-row>
        <b-col md="4">
          <b-form-group :label="searchTmp[1].label+':'">
            <select class="form-control" v-model="searchTmp[1].value">
              <option :value="null">Tout</option>
              <option v-for="(t,i) in params.ProprieteType" :value="t.id" :key="i">{{t.titre.fr}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[2].label+':'">
            <select class="form-control" v-model="searchTmp[2].value">
              <option :value="null">Tout</option>
              <option v-for="(t,i) in params.TransactionType" :value="t.id" :key="i">{{t.titre.fr}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[3].label+':'">
            <vue-google-autocomplete id="map" classname="form-control" placeholder="Adresse" types="(cities)" v-on:placechanged="getAddressData" country="il"> </vue-google-autocomplete>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[4].label+':'">
            <select class="form-control" v-model="searchTmp[4].value">
              <option :value="null">Tout</option>
              <option v-for="(t,i) in params.Quartier" :value="t.id" :key="i">{{t.titre.fr}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[5].label+':'">
            <b-form-input v-model.number="searchTmp[5].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[6].label+':'">
            <b-form-input v-model.number="searchTmp[6].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[8].label+':'">
            <b-form-input v-model.number="searchTmp[8].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[9].label+':'">
            <b-form-input v-model.number="searchTmp[9].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[7].label+':'">
            <select class="form-control" v-model="searchTmp[7].value">
              <option :value="null">Tout</option>
              <option v-for="(t,i) in params.ProprieteEtat" :value="t.id" :key="i">{{t.titre.fr}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[10].label+':'">
            <select class="form-control" v-model="searchTmp[10].value">
              <option :value="null">Tout</option>
              <option v-for="(t,i) in params.Utilisation" :value="t.id" :key="i">{{t.titre.fr}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[11].label+':'">
            <b-form-input v-model.number="searchTmp[11].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group :label="searchTmp[12].label+':'">
            <b-form-input v-model.number="searchTmp[12].value" type="number"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="addSearch()">
            Valide
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal id="createPropriete" title="Creation d'une propriete" size="sm" ref="createPropriete">
      <div class="row">
        <div class="form-group col-md-12">
          <label>Projet:</label>
          <model-select :options="projets" v-model.number="proprieteTmp.projet_id" placeholder="Selectionnez votre projet"></model-select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="createPropriete">
            {{$store.back.getText('sauvegarder')}}
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.createPropriete.hide();">
            {{$store.back.getText('fermer')}}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ModelSelect } from 'vue-search-select';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
export default {
  data(){
    return {
      toggleFiles:false,
      loader_save:false,
      loaderImportFile:false,
      docs_filter:null,
      tmpDoc:{
        "url": null,
        "ged_type": null,
        "date_create": null,
        "status": 1
      },
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      client:{
        "id": null,
        "nom": null,
        "prenom": null,
        "email": null,
        "telephone": null,
        "commentaire": "",
        "type_id":1,
        "status_id": 1,
        "status": 1,
        "date_create": null,
        "date_update": null,
        "telephone2":null,
        "langue_id":1,
        "taz":null,
        "societe":{
          "nom":null,
          "id":null,
          "adresse":null,
          "ville": null,
          "pays": null
        },
        "docs":[],
        "source_id":1,
        "searchs":[]
      },
      proprietes:[],
      searchTmp:[
        {
            "key":"date_create",
            "label":this.$store.back.getText('date_create'),
            "type":"String",
            "value":null,
            "algo":false
        },
        {
          "key":"type_id",
          "label":this.$store.back.getText('type'),
          "type":"Integer",
          "value":null,
          "algo":true
        },
        {
            "key":"transaction_type_id",
            "label":this.$store.back.getText('type_transaction'),
            "type":"Integer",
            "value":null,
            "algo":true
        },
        {
            "key":"adresse.locality",
            "label":this.$store.back.getText('ville'),
            "type":"String",
            "value":null,
            "algo":true
        },
        {
            "key":"adresse.quartier_id",
            "label":this.$store.back.getText('quartier'),
            "type":"Integer",
            "value":null,
            "algo":true
        },
        {
            "key":"min_superficie",
            "label":this.$store.back.getText('superficie_min'),
            "type":"Integer",
            "value":null,
            "algo":true
        },
        {
            "key":"max_superficie",
            "label":this.$store.back.getText('superficie_max'),
            "type":"Integer",
            "value":null,
            "algo":true
        },
        {
            "key":"etat_id",
            "label":this.$store.back.getText('etat'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"min_duree",
            "label":this.$store.back.getText('min_duree'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"max_duree",
            "label":this.$store.back.getText('max_duree'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"utilisation_id",
            "label":this.$store.back.getText('utilisation'),
            "type":"Integer",
            "value":null,
            "algo":false
        },
        {
            "key":"min_prix_transaction",
            "label":this.$store.back.getText('prix_min'),
            "type":"Integer",
            "value":true,
            "algo":false
        },
        {
            "key":"max_prix_transaction",
            "label":this.$store.back.getText('prix_max'),
            "type":"Integer",
            "value":true,
            "algo":false
        }
      ],
      citys:[],
      params:{
        ProprieteType:[],
        ProprieteStatus:[],
        ClientType:[],
        Langue:[],
        Source:[],
        GEDType:[],
        TransactionType:[],
        Quartier:[],
        Utilisation:[],
        ProprieteEtat:[]
      },
      projets:[],
      proprieteTmp:{
        client_id: null,
        projet_id:null
      }
    };
  },
  components: {
    ModelSelect,
    VueGoogleAutocomplete
  },
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
  },
  methods: {
    getAddressData(addressData) {
      this.searchTmp[3].value = addressData.locality;
    },
    save() {
      if(this.loader_save === true){
        return false;
      }
      this.loader_save = true;
      this.$store.back.ajax('/client/create', this.client, (res) => {
        if(res.status){
          if(this.client.id === null){
            this.$router.push({path:'/client/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.client = res.data;
            this.$bvToast.toast(`Client enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
          }
        }
        this.loader_save = false;
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.client.commentaire === null){
        this.client.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.client.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    addDoc(){
      var tmp = JSON.stringify(this.tmpDoc);
      tmp = JSON.parse(tmp)
      this.client.docs.push(tmp);
      this.tmpDoc.url = null;
      this.tmpDoc.ged_type = null;
      this.tmpDoc.date_create = null;
      this.$refs.import.hide();
    },
    getFileName(obj){
      var q = obj.url.slice(obj.url.indexOf("?")+1);
      var params = new URLSearchParams(q);
      var filename = params.get('q');
      filename = filename.slice(0,19-3)
      return filename+"...";
    },
    removeFile(index){
      this.client.docs.splice(index,1);
    },
    handleFile(e){
      var the = this;
      this.loaderImportFile = true;
      var files = e.target.files;
      files.forEach((item) => {
        the.upladFile(item);
      });
    },
    upladFile(file){
      const fileUploaded = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.back.ajax('/ged/import', tmp, (res) => {
          if(res.status == true){
            this.tmpDoc.url = res.data;
            this.tmpDoc.date_create = Math.floor(Date.now() / 1000);
            this.loaderImportFile = false;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    createPropriete(){
      this.proprieteTmp.client_id = this.client.id;
      this.$store.back.ajax('/client/propriete', this.proprieteTmp, (res) => {
        if(res.status){
          this.$router.push({path:'/propriete/detail/'+res.data.id});
        }
      })
    },
    addSearch(){
      var data = JSON.parse(JSON.stringify(this.searchTmp));
      data[0].value = Math.floor(Date.now() / 1000);
      this.client.searchs.push(data);
      this.$refs.search.hide();
      this.searchTmp.forEach((item) => {
        item.value = null
      });
    },
    removeSearch(index){
      this.client.searchs.splice(index,1);
    },
    openSearch(i){
      var search = this.client.searchs[i];
      var query = JSON.stringify(search);
      query = encodeURI(query);
      var url = location.origin+'/#/propriete/filter?query='+query
      window.open(url, '_blank').focus();
    },
    deleteClient(){
      this.client.status = 0;
      this.save();
      setTimeout(() => {
        this.$router.push({path:"/client/liste"})
      },1000);
    }
  },
  computed: {
    docsFiltered(){
      if(this.docs_filter === null){
        return this.client.docs;
      }else{
        return this.client.docs.filter(d => d.ged_type === this.docs_filter);
      }
    }
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.$store.back.ajax(`/client/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.client = res.data;
        }
      });
    }
    let params = {
      modules: ["ProprieteType","ProprieteStatus","ClientType","Langue","TransactionType","Source","GEDType","Quartier","Utilisation","ProprieteEtat"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.ProprieteType = res.data['ProprieteType'];
        this.params.ProprieteStatus = res.data['ProprieteStatus'];
        this.params.ClientType = res.data['ClientType'];
        this.params.Langue = res.data['Langue'];
        this.params.Source = res.data['Source'];
        this.params.GEDType = res.data['GEDType'];
        this.params.TransactionType = res.data['TransactionType'];
        this.params.Quartier = res.data['Quartier'];
        this.params.Utilisation = res.data['Utilisation'];
        this.params.ProprieteEtat = res.data['ProprieteEtat'];
        this.params.status = res.data['status'];
      }
    });
    this.$store.back.ajax('/projet/liste?page=1&limit=2000&sort=%7B%22field%22:%22id%22,%22value%22:%22DESC%22%7D&search=null', null, (res) => {
      res.data.forEach(item => {
        item.text = item.titre;
        item.value = item.id;
      });
      this.projets = res.data;
    });
    this.$store.back.ajax('/propriete/citys', null, (res) => {
      if(res.status){
        this.citys = res.data
      }
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    if(this.$route.params.id != undefined){
      var params = {
        field:"client_id",
        value: this.$route.params.id
      }
      this.$store.back.ajax(`/propriete/other`, params, (res) => {
        if(res.status){
          this.proprietes = res.data;
        }
      });
    }
  }
};
</script>
<style media="screen">
.pac-container {
  z-index: 1051 !important;
}
</style>
